<template>
  <v-app class="lh_app">
    <DashboardCoreDrawer />
    <DashboardCoreView />
  </v-app>
</template>

<script>
export default {
  name: 'Index',
  components: {
    DashboardCoreDrawer: () => import('@/components/core/Drawer'),
    DashboardCoreView: () => import('@/components/core/View'),
  },
  data: () => ({
    expandOnHover: false,
  }),
  async beforeMount() {
    await this.$store.dispatch('loadSalePoints')
    await this.$store.dispatch('loadCategories')
    await this.$store.dispatch('loadTaxonomies')
    await this.$store.dispatch('loadTaxonomyAttributes')
    await this.$store.dispatch('loadDrops')
  },
}
</script>

<style scoped>
.lh_app  {
  background-color: #FAFAFA;
}
</style>
